html,
body,
#root,
#app {
	height: 100%;
}

html {
	box-sizing: border-box;
	font-size: 62.5%;
	font-family: 'Inter';
}

body {
	margin: 0;
	padding: 0;
}

.light_theme {
	@import 'variables';
	@import 'main';
	@import 'button';
	@import 'header';
	@import 'page';
	@import 'login';
	@import 'form';
	@import 'icon';
	@import 'list';
	@import 'popup';
}

.dark_theme {
	@import 'variables_dark';
	@import 'main';
	@import 'button';
	@import 'header';
	@import 'page';
	@import 'login';
	@import 'form';
	@import 'icon';
	@import 'list';
	@import 'popup';
}
