.list {
	background-color: $boxBackgroundColor;
	width: 100%;
	border-radius: $boxRadius;
	box-shadow: $boxShadow;
	margin-bottom: 0.5rem;
	font-size: 1.2rem;
}

.list.inactive {
	background-color: $boxBackgroundColorDisabled;
}

.list.announce {
	background-color: $boxBackgroundColorAnnounce;
}

.list.green {
	background-color: $boxBackgroundColorGreen;
}

.list.red {
	background-color: $boxBackgroundColorRed;
}

.list_in {
	padding: 0.5rem 1rem;

	&.lnheader {
		color: $textColorWhite;
	}
}

.topay {
	background-color: #dd3333;
	border-radius: $boxRadius;
	padding: 0.3rem 1rem 0.4rem 1rem;
	color: #eeeeee;
}

.overpay {
	background-color: #33aa33;
	border-radius: $boxRadius;
	padding: 0.3rem 1rem 0.4rem 1rem;
	color: #eeeeee;
}

.message {
	background-color: #aaaaaa;
	border-radius: $boxRadius;
	padding: 0.3rem 1rem 0.4rem 1rem;
	color: #eeeeee;
}

.tab_panel_scroll {
	width: 100%;
	height: 3rem;
	display: inline-flex;
	white-space: nowrap;
	overflow-y: hidden;
	overflow-x: auto;
}

.list_tab {
	float: left;
	width: 6rem;
	height: 3rem;
	background-color: #777777;
	border-radius: $boxRadius $boxRadius 0 0;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	font-size: 1.3rem;
	cursor: pointer;
	transition: all 0.3s linear;
	color: $textColorWhite;
}

.list_tab_in {
	margin: 0.5rem 0.5rem 0.5rem 0.5rem;
	overflow: hidden;

	&.lnheader {
		color: $textColorWhite;
	}
}

.list_tab_bottom {
	height: 0.4rem;
	border-radius: 0px;
}

.list_tab_in_cl {
	float: left;
	margin: 0.2rem 0.3rem 0rem 0rem;
	width: 0.5rem;
	height: 1.3rem;
}
