$themeName: 'dark';

$appBackgroundColor: #888888;
$appTextColor: #eeeeee;
$appTextSize: 1.6rem;

$cookiesBackgroundColor: #ff9900;
$textColorOrange: #ff9900;
$textColorGreen: #66bb66;
$textColorYellow: #ffff00;
$textColorGray: #aaaaaa;
$textColorWhite: #ffffff;
$textColorRed: #dd2200;
$textColorMessage: #ffffff;

$boxBackgroundColor: #555555;
$boxBackgroundColorDisabled: #777777;
$boxBackgroundColorAnnounce: #d7932d;
$boxBackgroundColorGreen: #66bb66;
$boxBackgroundColorGreen2: #009944;
$boxBackgroundColorRed: #dd2200;
$boxBackgroundColorRed2: #cc4444;
$boxShadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.3);
$boxRadius: 7px;

$roundRadius: 50%;

$formTextSize: 1.3rem;
$formTextColor: #eeeeee;
$formTextColorDisabled: #999999;
$formTextColorError: #ff9900;
$formBackgroundColor: #777777;
$formBackgroundColorDisabled: #dddddd;
$formBorder: 2px solid #aaaaaa;
$formBorderDisabled: 2px solid #dddddd;
$formBorderFocus: 2px solid #dddddd;
$formPlaceholderColor: #999999;
$formPadding: 1.4rem 0.6rem;
$formLabelTextSize: 1.2rem;
$formResultTextSize: 1.4rem;
$formPaddintTextareat: 0.4rem 0.6rem;
$formBorderSearch: 1px solid #aaaaaa;
$formBorderFocusSearch: 1px solid #dddddd;
$formCalendarBackgroundColorToday: #bbbb88;
$formCalendarBackgroundColorSelected: #ff9900;

$buttonBackgroundColor: #555555;
$buttonTextColor: #dddddd;
$buttonBackgroundColorGreen: #338833;
$buttonBackgroundColorRed: #dd2200;
$buttonTextColorColored: #ffffff;
$buttonBorder: 2px solid #aaaaaa;
$buttonBackgroundDisabled: #999999;
$buttonBorderDisabled: 2px solid #dddddd;
$buttonTextColorDisabled: #dddddd;
$buttonBorderFocus: 2px solid #dddddd;
$buttonBackgroundColorHover: #999999;

$popupBackground: rgba(0, 0, 0, 0.5);
$popupBackgroundColorHead: #888888;
$popupBackgroundColorBody: #555555;
$popupBackgroundColorFoot: #888888;
$popupTextSize: 1.4rem;
