.header {
	height: 3.2rem;
	width: calc(100% - 2rem);
	max-width: 60rem;
	padding: 1rem;
	margin-left: auto;
	margin-right: auto;
}

.header.more {
	height: 7.4rem;
}

.usermenu {
	position: absolute;
	top: 4rem;
	right: 0;
	width: 16.2rem;
	min-height: 5rem;
	max-height: calc(100vh - 14rem);
	overflow-y: auto;
	overflow-x: hidden;
	background-color: $buttonBackgroundColor;
	border-radius: $boxRadius;
	box-shadow: $boxShadow;
	justify-content: center;
	align-items: center;
	font-size: 1.3rem;
	padding: 1rem 1rem 0.5rem 1rem;
	color: $buttonTextColor;
	cursor: default;
	z-index: 100;
	border: $formBorder;
	scrollbar-width: none; /* For Firefox */
	-ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.usermenu::-webkit-scrollbar {
	display: none; /* For Chrome, Safari and Opera */
}

.usermenu_email {
	width: 14rem;
	height: 1.8rem;
	padding: 0rem 0.5rem 0.5rem 0.5rem;
	text-decoration: none;
	margin-bottom: 0.5rem;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	//font-weight: bold;
	cursor: pointer;
	text-align: center;
}

.usermenu_doorkey {
	width: 14rem;
	height: 1.8rem;
	padding: 0rem 0.5rem 0.5rem 0.5rem;
	text-decoration: none;
	margin-bottom: 0.5rem;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	//font-weight: bold;
	cursor: pointer;
	text-align: center;
}

.usermenu_item {
	width: calc(100% - 3.7rem);
	height: 1.8rem;
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: $boxRadius;
	padding: 0.5rem;
	background-color: $buttonBackgroundColor;
	text-decoration: none;
	color: $buttonTextColor;
	border: $formBorder;
	margin-bottom: 0.5rem;
	background-size: 1.5rem;
	background-repeat: no-repeat;
	background-position: 0.5rem center;
	padding-left: 2.8rem;
}

.usermenu_item.active {
	background-color: $formCalendarBackgroundColorToday;
	border: $formBorder;
}

.usermenu_item:hover {
	background-color: $buttonBackgroundColorHover;
	border: $formBorder;
}
