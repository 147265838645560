@font-face {
	font-family: 'Inter';
	src: url('./fonts/Inter-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./fonts/Inter-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./fonts/Inter-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./fonts/Inter-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./fonts/Inter-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./fonts/Inter-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./fonts/Inter-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('./fonts/Inter-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}
