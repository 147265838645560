.popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $popupBackground;
	z-index: 1000;
	font-size: $popupTextSize;
}

.popup_content {
	position: absolute;
	top: 2rem;
	left: 50%;
	transform: translate(-50%);
	width: 90%;
	max-width: 45rem;
	max-height: 90%;
	background-color: $popupBackgroundColorBody;
	border-radius: $boxRadius;
	box-shadow: $boxShadow;
	display: flex;
	flex-direction: column;
}

.popup_content_in_head {
	width: 100%;
	background-color: $popupBackgroundColorHead;
	border-radius: $boxRadius $boxRadius 0 0;
}

.popup_content_in_head_in {
	padding: 1rem;
}

.popup_content_in_body {
	width: 100%;
	max-height: calc(100% - 6rem);
	overflow-y: auto;
	text-align: center;
}

.popup_content_in_body_in {
	padding: 1rem;
}

.popup_content_in_foot {
	width: 100%;
	background-color: $popupBackgroundColorFoot;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-radius: 0 0 $boxRadius $boxRadius;
}

.popup_content_in_foot_in {
	padding: 1rem;
}

.popup_content_in_head_in-close {
	height: 3rem;
	cursor: pointer;
	border-radius: 5px;
	position: absolute;
	right: 0.5rem;
	top: 0.5rem;
	content: url('../../public/images/del#{$themeName}.svg');
}
