.login {
	height: 100%;
	width: 100%;
	background-color: $appBackgroundColor;
	font-size: $appTextSize;
	color: $appTextColor;
}

.login_logo {
	width: 50%;
	max-width: 15rem;
	content: url('../../public/images/logo.svg');
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.login_container {
	background-color: $boxBackgroundColor;
	width: 90%;
	max-width: 45rem;
	border-radius: $boxRadius;
	box-shadow: $boxShadow;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.login_container_in {
	padding: 2rem;
}

.login_result {
	font-size: $formResultTextSize;
}
