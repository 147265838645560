.icon {
	width: 2.5rem;
	height: 2.5rem;
	background-size: 2rem;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	background-color: $buttonBackgroundColor;
	border-radius: 5px;
	padding: 0.3rem;
	box-shadow: $boxShadow;
}

.icon:hover {
	background-color: $buttonBackgroundColorHover;
}

.icon.menu {
	background-image: url('../../public/images/menu#{$themeName}.svg');
	position: relative;
}

.icon.pay {
	background-image: url('../../public/images/pay#{$themeName}.svg');
}

.icon.rule {
	background-image: url('../../public/images/rule#{$themeName}.svg');
}

.icon.key {
	background-image: url('../../public/images/key#{$themeName}.svg');
}

.icon.key2 {
	background-image: url('../../public/images/key2.svg');
}

.icon.keyon {
	background-image: url('../../public/images/keyon.svg');
}

.icon.eshop {
	background-image: url('../../public/images/eshop#{$themeName}.svg');
}

.icon.email {
	background-image: url('../../public/images/email#{$themeName}.svg');
}

.icon.exercise {
	background-image: url('../../public/images/exercise#{$themeName}.svg');
}

.icon.payment_sysadmin {
	background-image: url('../../public/images/pay_sysadmin#{$themeName}.svg');
}

.icon.trans_sysadmin {
	background-image: url('../../public/images/trans_sysadmin#{$themeName}.svg');
}

.icon.eshop_sysadmin {
	background-image: url('../../public/images/eshop_sysadmin#{$themeName}.svg');
}

.icon.user {
	background-image: url('../../public/images/user_sysadmin#{$themeName}.svg');
}

.icon.member {
	background-image: url('../../public/images/payment_sysadmin#{$themeName}.svg');
}

.icon_theme {
	content: url('../../public/images/light#{$themeName}.svg');
	height: 2rem;
}

.icon_exerciseplan {
	content: url('../../public/images/exerciseplan#{$themeName}.svg');
	height: 2.5rem;
}

.icon_refresh {
	content: url('../../public/images/refresh#{$themeName}.svg');
	height: 2.3rem;
}

.icon_refresh_main {
	float: left;
	width: 2.3rem;
	height: 2.4rem;
	position: relative;
	cursor: pointer;
}

.icon_refresh_in {
	width: 2.3rem;
	height: 2.4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	font-size: 0.9rem;
}

.icon_timer {
	content: url('../../public/images/timer#{$themeName}.svg');
	height: 3.5rem;
}

.icon_left {
	content: url('../../public/images/left#{$themeName}.svg');
	height: 2.3rem;

	&.disabled {
		opacity: 0.5;
	}
}

.icon_right {
	content: url('../../public/images/right#{$themeName}.svg');
	height: 2.3rem;

	&.disabled {
		opacity: 0.5;
	}
}

.icon1 {
	height: 1.5rem;
}

.icon2 {
	height: 2rem;
}

.icon1.del {
	content: url('../../public/images/del#{$themeName}.svg');
	height: 2.9rem;
	position: relative;
	top: 0.2rem;

	&.small {
		height: 3.4rem;
		position: absolute;
		top: -0.7rem;
	}
}

.icon2.user {
	content: url('../../public/images/user_sysadmin.svg');
}

.icon2.userlog {
	content: url('../../public/images/userlog.svg');
	height: 2.4rem;
}

.icon1.trans {
	content: url('../../public/images/trans_sysadmin.svg');
}

.icon2.permanent2 {
	content: url('../../public/images/pay_sysadmin.svg');
}

.icon2.qr {
	content: url('../../public/images/qr.png');
}

.icon1.member {
	content: url('../../public/images/payment_sysadmin2#{$themeName}.svg');
}

.icon1.member.active {
	content: url('../../public/images/payment_sysadmin.svg');
}

.icon2.member {
	content: url('../../public/images/payment_sysadmin2#{$themeName}.svg');
}

.icon2.member.active {
	content: url('../../public/images/payment_sysadmin.svg');
}

.icon1.key {
	content: url('../../public/images/key#{$themeName}.svg');
}

.icon1.key.active {
	content: url('../../public/images/key2.svg');
}

.icon2.key {
	content: url('../../public/images/key#{$themeName}.svg');
}

.icon2.key.active {
	content: url('../../public/images/key2.svg');
}

.icon1.permanent {
	content: url('../../public/images/pay_sysadmin2#{$themeName}.svg');
}

.icon1.permanent.active {
	content: url('../../public/images/pay_sysadmin.svg');
}

.icon2.permanent {
	content: url('../../public/images/pay_sysadmin2#{$themeName}.svg');
}

.icon2.permanent.active {
	content: url('../../public/images/pay_sysadmin.svg');
}

.icon1.edit {
	content: url('../../public/images/edit#{$themeName}.svg');
}

.icon1.copy {
	content: url('../../public/images/copy#{$themeName}.svg');
}

.icon2.copy {
	content: url('../../public/images/copy#{$themeName}.svg');
}

.icon2.edit {
	content: url('../../public/images/edit#{$themeName}.svg');
}

.icon1.edit2 {
	content: url('../../public/images/edit2.svg');
}

.icon1.edit3 {
	content: url('../../public/images/edit3.svg');
}

.icon1.ok0 {
	content: url('../../public/images/ok0#{$themeName}.svg');
}

.icon1.ok1 {
	content: url('../../public/images/ok1.svg');
}

.icon1.pdf {
	content: url('../../public/images/pdf#{$themeName}.svg');
	position: relative;
	top: 0.2rem;
}

.icon1.pdf2 {
	content: url('../../public/images/pdf#{$themeName}.svg');
	height: 2rem;
	position: relative;
	top: 0.5rem;
}

.icon2.logas {
	content: url('../../public/images/logas#{$themeName}.svg');
}

.icon1.lock {
	content: url('../../public/images/lock#{$themeName}.svg');
}

.icon1.lock.active {
	content: url('../../public/images/lock2.svg');
}

.icon2.lock {
	content: url('../../public/images/lock#{$themeName}.svg');
}

.icon2.lock.active {
	content: url('../../public/images/lock2.svg');
}

.icon1.eshop {
	content: url('../../public/images/eshop#{$themeName}.svg');
}

.icon1.eshop.active {
	content: url('../../public/images/eshop_sysadmin.svg');
}

.icon2.eshop {
	content: url('../../public/images/eshop#{$themeName}.svg');
}

.icon2.eshop.active {
	content: url('../../public/images/eshop_sysadmin.svg');
}

.icon1.arrowright {
	content: url('../../public/images/arrowright#{$themeName}.svg');
}

.icon1.arrowup {
	content: url('../../public/images/arrowup#{$themeName}.svg');
}

.usermenu_item.home {
	background-image: url('../../public/images/key#{$themeName}.svg');
}

.usermenu_item.exercise {
	background-image: url('../../public/images/exercise#{$themeName}.svg');
}

.usermenu_item.workoutmax {
	background-image: url('../../public/images/max#{$themeName}.svg');
}

.usermenu_item.workout {
	background-image: url('../../public/images/workout#{$themeName}.svg');
}

.usermenu_item.stat {
	background-image: url('../../public/images/stats#{$themeName}.svg');
}

.usermenu_item.chart {
	background-image: url('../../public/images/charts#{$themeName}.svg');
}

.usermenu_item.email {
	background-image: url('../../public/images/email#{$themeName}.svg');
}

.usermenu_item.eshop {
	background-image: url('../../public/images/eshop#{$themeName}.svg');
}

.usermenu_item.pay {
	background-image: url('../../public/images/pay#{$themeName}.svg');
}

.usermenu_item.permanent {
	background-image: url('../../public/images/pay_sysadmin2#{$themeName}.svg');
}

.usermenu_item.logout {
	background-image: url('../../public/images/logout#{$themeName}.svg');
}

.usermenu_item.eshop_sysadmin {
	background-image: url('../../public/images/eshop_sysadmin.svg');
}

.usermenu_item.payment {
	background-image: url('../../public/images/pay_sysadmin.svg');
}

.usermenu_item.messagesysadmin {
	background-image: url('../../public/images/email2.svg');
}

.usermenu_item.worknoutsysadmin {
	background-image: url('../../public/images/workoutsysadmin.svg');
}

.usermenu_item.member {
	background-image: url('../../public/images/payment_sysadmin.svg');
}

.usermenu_item.trans {
	background-image: url('../../public/images/trans_sysadmin.svg');
}

.usermenu_item.user {
	background-image: url('../../public/images/user_sysadmin.svg');
}

.usermenu_item.keys {
	background-image: url('../../public/images/key2.svg');
}

.usermenu_item.userlog {
	background-image: url('../../public/images/userlog.svg');
}

.usermenu_item.setting {
	background-image: url('../../public/images/setting#{$themeName}.svg');
}

.usermenu_item.eshopitem {
	background-image: url('../../public/images/notes.svg');
}

.usermenu_item.note {
	background-image: url('../../public/images/notes2#{$themeName}.svg');
}

.usermenu_item.musclegroup {
	background-image: url('../../public/images/musclegroup#{$themeName}.svg');
}

.usermenu_item.timer {
	background-image: url('../../public/images/timer#{$themeName}.svg');
}

.icon_thunder {
	content: url('../../public/images/thunder.svg');
	background-color: #ffcc00;
	border-radius: $roundRadius;
	height: 1.3rem;

	&.small {
		width: 1.1rem;
		height: 1.1rem;
	}

	&.big {
		width: 1.5rem;
		height: 1.5rem;
	}
}

.icon_thunder2 {
	content: url('../../public/images/thunder.svg');
	background-color: #ffcc00;
	border-radius: $roundRadius;
	margin-top: 0.25rem;
	height: 1.1rem;
}

.icon_topay {
	background-color: #dd3333;
	border-radius: $roundRadius;
	width: 1.3rem;
	height: 1.3rem;

	&.small {
		width: 1.1rem;
		height: 1.1rem;
	}

	&.big {
		width: 1.5rem;
		height: 1.5rem;
	}
}

.icon_overpay {
	background-color: #33aa33;
	border-radius: $roundRadius;
	width: 1.3rem;
	height: 1.3rem;

	&.small {
		width: 1.1rem;
		height: 1.1rem;
	}

	&.big {
		width: 1.5rem;
		height: 1.5rem;
	}
}

.icon_message {
	background-color: #aaaaaa;
	border-radius: $roundRadius;
	width: 1.3rem;
	height: 1.3rem;

	&.small {
		width: 1.1rem;
		height: 1.1rem;
	}

	&.big {
		width: 1.5rem;
		height: 1.5rem;
	}
}

.icon_notpayedaddperson {
	background-color: #ff9900;
	border-radius: $roundRadius;
	width: 1.3rem;
	height: 1.3rem;

	&.small {
		width: 1.1rem;
		height: 1.1rem;
	}

	&.big {
		width: 1.5rem;
		height: 1.5rem;
	}
}

.icon_eshop_item {
	height: 2.6rem;
	position: absolute;
	top: -0.3rem;
	left: 50%;
	transform: translateX(-50%);
}

.icon_eshop_del {
	content: url('../../public/images/del#{$themeName}.svg');
	height: 2.6rem;
	position: absolute;
	top: -0.3rem;
	right: -0.3rem;
}
