.page_main {
	height: 100%;
	width: 100%;
	background-color: $appBackgroundColor;
	font-size: $appTextSize;
	color: $appTextColor;
}

.top {
	width: calc(100% - 2rem);
	max-width: 60rem;
	height: 2rem;
	padding: 0rem 1rem 1rem 1rem;
	margin-left: auto;
	margin-right: auto;
}

.page {
	width: calc(100% - 2rem);
	max-width: 60rem;
	height: calc(100% - 10rem);
	overflow: auto;
	padding: 0.5rem 1rem 1rem 1rem;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0.2rem;
	background-color: $appBackgroundColor;
	text-align: center;
	scrollbar-width: none; /* For Firefox */
	-ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.page::-webkit-scrollbar {
	display: none; /* For Chrome, Safari and Opera */
}

.page.more {
	height: calc(100% - 14.1rem);
}

.sticky {
	position: sticky;
	top: 0;
	background-color: $appBackgroundColor;
	margin-top: 0rem;
	padding-bottom: 0.01rem;
	z-index: 10;
}
