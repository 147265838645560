.input-div {
	width: 100%;
	margin-bottom: 0.9rem;
}

.input-label {
	font-family: 'Inter';
	font-size: $formLabelTextSize;
	color: $formTextColor;
	float: left;
	margin-left: 0.8rem;
	margin-bottom: 0.3rem;
}

.input-error {
	font-family: 'Inter';
	font-size: $formLabelTextSize;
	color: $formTextColorError;
	float: right;
	margin-right: 0.5rem;
	margin-bottom: 0.3rem;
}

.input-text {
	width: 100%;
	height: 3rem;
	border-radius: $boxRadius;
	font-family: 'Inter';
	outline: none;
	font-size: $formTextSize;
	color: $formTextColor;
	border: $formBorder;
	background: $formBackgroundColor;
	box-sizing: border-box;
	padding: $formPadding;

	&.small {
		height: 2rem;
		padding: 0.8rem 0.6rem;
		font-size: 1.2rem;
	}
}

.input-text.color {
	width: 100%;
	height: 3rem;
	border-radius: $boxRadius;
	font-family: 'Inter';
	outline: none;
	font-size: $formTextSize;
	color: $formTextColor;
	border: $formBorder;
	background: $formBackgroundColor;
	box-sizing: border-box;
	padding: 0rem;
}

.input-text:active,
.input-text:focus {
	border: $formBorderFocus;
}

.input-text::placeholder {
	color: $formPlaceholderColor;
}

.input-textarea {
	width: 100%;
	height: 7rem;
	border-radius: $boxRadius;
	font-family: 'Inter';
	outline: none;
	font-size: $formTextSize;
	color: $formTextColor;
	border: $formBorder;
	background: $formBackgroundColor;
	box-sizing: border-box;
	padding: $formPaddintTextareat;
	resize: none;
}

.input-textarea:active,
.input-textarea:focus {
	border: $formBorderFocus;
}

.input-textarea::placeholder {
	color: $formPlaceholderColor;
}

.input-text-search {
	width: 11rem;
	height: 2rem;
	border-radius: $boxRadius;
	font-family: 'Inter';
	outline: none;
	font-size: 1.4;
	color: $formTextColor;
	border: $formBorderSearch;
	background: $formBackgroundColor;
	box-sizing: border-box;
	padding: 1.2rem 2.5rem 1.2rem 0.6rem;
}

.input-text-search:active,
.input-text-search:focus {
	border: $formBorderFocusSearch;
}

.input-text-search::placeholder {
	color: #cccccc;
}

.input-text-search-del {
	height: 2.5rem;
	cursor: pointer;
	border-radius: 5px;
	position: absolute;
	right: 1.2rem;
	top: 0.1rem;
	content: url('../../public/images/del#{$themeName}.svg');
}

.input-text-date-cal-icon {
	height: 2rem;
	cursor: pointer;
	border-radius: 5px;
	position: absolute;
	right: 0.7rem;
	top: 2.4rem;
	content: url('../../public/images/calendar#{$themeName}.svg');
}

.input-text-date-cal-icon2 {
	height: 1.5rem;
	cursor: pointer;
	border-radius: 5px;
	content: url('../../public/images/calendar#{$themeName}.svg');
}

.input-text-date-cal-icon3 {
	height: 2.2rem;
	cursor: pointer;
	border-radius: 5px;
	content: url('../../public/images/calendar#{$themeName}.svg');
}

.calendar {
	width: 100%;
	height: 100%;
	border-top: 1px solid #cccccc;
	border-left: 1px solid #cccccc;
}

.calendarin {
	padding: 2rem;
}

.calendarin-head {
	height: 3rem;
	justify-content: space-between;
	align-items: center;
	border-right: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
}

.calendar-day {
	width: 3rem;
	height: 3rem;
	justify-content: center;
	align-items: center;
	border-right: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
}

.calendar-day.gray {
	color: $formTextColorDisabled;
}

.calendar-day.curday {
	background-color: $formCalendarBackgroundColorToday;
}

.calendar-day.selday {
	background-color: $formCalendarBackgroundColorSelected;
}

.calendar-dot {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	float: left;
	position: absolute;
	top: 0.3rem;
}

.select_item {
	width: calc(100% - 1.4rem);
	height: 1.8rem;
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: $boxRadius;
	padding: 0.5rem;
	background-color: $buttonBackgroundColor;
	text-decoration: none;
	color: $buttonTextColor;
	border: $formBorderDisabled;
	margin-bottom: 0.5rem;
	background-size: 1.5rem;
	background-repeat: no-repeat;
	background-position: 0.5rem center;
}

.select_item.selected {
	background-color: $formCalendarBackgroundColorToday;
}

.select_item:hover {
	background-color: $buttonBackgroundColorHover;
	border: $formBorder;
}

.select_item.selected:hover {
	background-color: $buttonBackgroundColorHover;
	border: $formBorder;
}
