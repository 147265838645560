.loading {
	width: 2.2rem;
	height: 2.2rem;
	position: absolute;
	top: 0.5rem;
	left: calc(0.5rem);
	z-index: 1000;
	padding: 4px;
	aspect-ratio: 1;
	border-radius: 50%;
	background: $appTextColor;
	--_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
	-webkit-mask: var(--_m);
	mask: var(--_m);
	-webkit-mask-composite: source-out;
	mask-composite: subtract;
	animation: l3 1s infinite linear;
}
@keyframes l3 {
	to {
		transform: rotate(1turn);
	}
}

.fnt_orange {
	color: $textColorOrange !important;
}

.fnt_green {
	color: $textColorGreen !important;
}

.fnt_red {
	color: $textColorRed !important;
}

.fnt_yellow {
	color: $textColorYellow !important;
}

.fnt_gray {
	color: $textColorGray !important;
}

.fnt_white {
	color: $textColorWhite !important;
}

.fnt_message {
	color: $textColorMessage !important;
}

.lnk1 {
	color: $appTextColor;
}

.lnk2 {
	text-decoration: underline;
}

.cookies {
	width: 90%;
	max-width: 45rem;
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
	width: 100%;
	background-color: $cookiesBackgroundColor;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	z-index: 1000;
}

.qrcode {
	background-color: white;
	width: 200px;
	margin-left: auto;
	margin-right: auto;
}

.qrcode.smaller {
	width: 150px;
}

.cookies_in {
	padding: 1rem;
}

.fl_l {
	float: left;
}

.fl_r {
	float: right;
}

.txt_l {
	text-align: left;
}

.txt_r {
	text-align: right;
}

.txt_c {
	text-align: center;
}

.mr_01 {
	margin-right: 0.1rem;
}

.mr_03 {
	margin-right: 0.3rem;
}

.mr_05 {
	margin-right: 0.5rem;
}

.mr_1 {
	margin-right: 1rem;
}

.mr_13 {
	margin-right: 1.3rem;
}

.mr_15 {
	margin-right: 1.5rem;
}

.mr_2 {
	margin-right: 2rem;
}

.ml_05 {
	margin-left: 0.5rem;
}

.ml_07 {
	margin-left: 0.7rem;
}

.ml_1 {
	margin-left: 1rem;
}

.ml_15 {
	margin-left: 1.5rem;
}

.ml_2 {
	margin-left: 2rem;
}

.ml_3 {
	margin-left: 3rem;
}

.ml_4 {
	margin-left: 4rem;
}

.mt_01 {
	margin-top: 0.1rem;
}

.mt_02 {
	margin-top: 0.2rem;
}

.mt_03 {
	margin-top: 0.3rem;
}

.mt_04 {
	margin-top: 0.4rem;
}

.mt_05 {
	margin-top: 0.5rem;
}

.mt_07 {
	margin-top: 0.7rem;
}

.mt_08 {
	margin-top: 0.8rem;
}

.mt_1 {
	margin-top: 1rem;
}

.mt_2 {
	margin-top: 2rem;
}

.hidden {
	display: none;
}

.zero {
	width: 100%;
	clear: both;
	margin-left: auto;
	margin-right: auto;
}

.zero.h0 {
	height: 0rem;
}

.zero.h02 {
	height: 0.2rem;
}

.zero.h03 {
	height: 0.3rem;
}

.zero.h05 {
	height: 0.5rem;
}

.zero.h07 {
	height: 0.7rem;
}

.zero.h1 {
	height: 1rem;
}

.zero.h15 {
	height: 1.5rem;
}

.zero.h2 {
	height: 2rem;
}

.zero.h3 {
	height: 3rem;
}

.zero.h4 {
	height: 4rem;
}

.cr_p {
	cursor: pointer;
}

.cr_d {
	cursor: default;
}

.cr_n {
	cursor: not-allowed;
}

.pos_r {
	position: relative;
}

.pos_a {
	position: absolute;
}

.pay_qr {
	height: 18rem;
	content: url('../../public/images/pay_qr.png');
}

.fnt_09 {
	font-size: 0.9rem;
}

.fnt_1 {
	font-size: 1rem;
}

.fnt_11 {
	font-size: 1.1rem;
}

.fnt_12 {
	font-size: 1.2rem;
}

.fnt_13 {
	font-size: 1.3rem;
}

.fnt_14 {
	font-size: 1.4rem;
}

.fnt_15 {
	font-size: 1.5rem;
}

.fnt_16 {
	font-size: 1.6rem;
}

.fnt_2 {
	font-size: 2rem;
}

.fnt_25 {
	font-size: 2.5rem;
}

.fnt_3 {
	font-size: 3rem;
}

.fnt_35 {
	font-size: 3.5rem;
}

.fnt_4 {
	font-size: 4rem;
}

.fnt_5 {
	font-size: 5rem;
}

.fnt_10 {
	font-size: 10rem;
}

.fnt_b {
	font-weight: bold;
}

.eshop_item {
	width: 70px;
	height: 103px;
	float: left;
	text-align: center;
	border: $buttonBorder;
	padding: 10px 5px 5px 5px;
	margin: 0px 7px 7px 0px;
	background-color: $boxBackgroundColor;
	border-radius: $boxRadius;
	box-shadow: $boxShadow;
}

.eshop_item:hover {
	border: $formBorderFocus;
}

.eshop_item_img {
	width: 100%;
	height: 50px;
	background-color: $boxBackgroundColor;
}
.eshop_item_img_img {
	max-width: 66px;
	max-height: 100%;
	overflow: hidden;
}
.eshop_item_text {
	width: 100%;
	height: 25px;
	overflow: hidden;
}
.eshop_item_price {
	width: 100%;
	height: 16px;
	overflow: hidden;
	margin-top: 3px;
}

.reservation {
	background-color: $boxBackgroundColor;
	width: 100%;
	border-radius: $boxRadius;
	box-shadow: $boxShadow;
	margin-bottom: 0.5rem;
	font-size: 1.3rem;
}

.reservation_top {
	width: calc(100% - 1.6rem);
	padding: 0.4rem 0.8rem 0.4rem 0.8rem;
	float: left;
	text-align: left;
	position: relative;
}

.reservation_top_perc {
	width: calc(100% - 0.8rem);
	position: absolute;
	top: 0rem;
	left: 0rem;
	opacity: 0.3;
}

.reservation_top_perc_in {
	width: 80%;
	height: 2.4rem;
	background-color: black;
	border-radius: $boxRadius;
}

.reservation_in {
	width: calc(100% - 1.6rem);
	border-radius: 0 0 $boxRadius $boxRadius;
	padding: 0rem 0.8rem 0rem 0.8rem;

	&.green {
		background-color: $boxBackgroundColorGreen2;
	}

	&.red {
		background-color: $boxBackgroundColorRed2;
	}
}

.noselectable {
	user-select: none;
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE/Edge */
}

.chart_roy_main {
	width: 100%;
	height: 1.5rem;
	border: 1px #888888 solid;
	background: rgba(100, 100, 100, 0.8);
	border-radius: 5px;
	font-size: 1.3rem;
}
.chart_roy_in {
	height: 100%;
	text-align: center;
	vertical-align: middle;
	max-width: 100%;
	border-radius: 5px;
}
.chart_roy_label {
	position: relative;
	top: 0px;
	margin: 0px 5px 0px 5px;
}
